<template>
    <Button label="Edit" @click="showDialog" class="px-3 py-2"></Button>
    <Dialog v-model:visible="show" modal header="Edit Claim" :style="{ width: '78rem' }">
        <div class="flex flex-column gap-3">
            <div class="flex gap-4 w-full justify-content-between">
                <div>
                    <div class="mb-1 text-lg font-medium">Date</div>
                    <Calendar v-model="initialData.date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
                <div>
                    <div class="mb-1 text-lg font-medium">Status</div>
                    <Dropdown v-model="initialData.status" :options="statusOptions" placeholder="Select a Status"
                        class="w-full md:w-14rem" />
                </div>
            </div>
            <div class="w-6">
                <div class="mb-1 text-lg font-medium">Agent Name</div>
                <InputText class="" v-model="initialData.agent_name" type="text"
                    :invalid="v$dialogAdd.agent_name.$errors.length > 0" autofocus />
            </div>
            <div class="w-6" v-if="initialData.claim_type == 'Customer'">
                <div class="mb-1 text-lg font-medium">Customer Name</div>
                <InputText class="" v-model="initialData.customer_name" type="text" />
            </div>
            <div class="flex gap-4 w-full" v-if="initialData.claim_type == 'Customer'">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Contact Detail 1</div>
                    <InputText class="" v-model="initialData.contact_details_1" type="text"
                        :invalid="v$dialogAdd.contact_details_1.$errors.length > 0" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Contact Detail 2 <span
                            class="font-normal text-base">(Optional)</span></div>
                    <InputText class="" v-model="initialData.contact_details_2" type="text" />
                </div>
            </div>

            <div class="flex gap-4 w-full" v-if="initialData.claim_type == 'KM Stock'">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">IS Date <span class="font-normal text-base">(Optional)</span>
                    </div>
                    <Calendar v-model="initialData.is_date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">IS Doc No <span
                            class="font-normal text-base">(Optional)</span>
                    </div>
                    <InputText class="" v-model="initialData.is_no" type="text" />
                </div>

                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">RC Date <span class="font-normal text-base">(Optional)</span>
                    </div>
                    <Calendar v-model="initialData.rc_date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">RC Doc No <span
                            class="font-normal text-base">(Optional)</span>
                    </div>
                    <InputText class="" v-model="initialData.rc_no" type="text" />
                </div>
            </div>

            <div class="flex gap-4">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Brand</div>
                    <InputText class="" v-model="initialData.claim_brand.name" disabled type="text" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Category</div>
                    <InputText class="" v-model="initialData.claim_category.name" disabled type="text" />
                </div>
            </div>
            <div class="flex gap-4 w-full">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Item</div>
                    <InputText class="" v-model="initialData.itemName" disabled type="text" />
                </div>
                <div class="w-6 flex gap-3">
                    <div class="w-6">
                        <div class="mb-1 text-lg font-medium">Quantity</div>
                        <InputNumber v-model="initialData.quantity" inputId="integeronly" class="w-12" disabled />
                    </div>
                    <div class="w-6" v-if="initialData.claim_type == 'Customer'">
                        <div class="mb-1 text-lg font-medium">Quantity in Shop <i class="pi pi-question-circle"
                                style="font-size: 1rem"
                                v-tooltip.top="'If customer brought back home, no item in shop then quantity is shop is 0. \n\nIf not, then key in how many quantity did customer left in our shop.'"></i>
                        </div>
                        <InputNumber :invalid="v$dialogAdd.quantity_in_shop.$errors.length > 0"
                            v-model="initialData.quantity_in_shop" inputId="integeronly" class="w-12" />
                    </div>
                </div>
            </div>
            <div class="">
                <div class="mb-1 text-lg font-medium">Problem <i class="pi pi-question-circle" style="font-size: 1rem"
                        v-tooltip.top="'Problem with the item. EG: Motor Rosak.'"></i></div>
                <Textarea v-model="initialData.problem" rows="3"></Textarea>
            </div>

            <div class="">
                <div class="mb-1 text-lg font-medium">Remark <i class="pi pi-question-circle" style="font-size: 1rem"
                        v-tooltip.top="'Other remarks regarding the claim.'"></i></div>
                <Textarea v-model="initialData.remark" rows="5"></Textarea>
            </div>

        </div>

        <!-- Error message -->
        <div v-if="initialData.errorMessage" class="mt-2 text-red-600" style="white-space: pre;">{{
            initialData.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end mt-6">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="show = false"
                :loading="isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveEditClaim()" :loading="isLoading" />
        </div>
    </Dialog>

    <Dialog v-model:visible="inputNameDialog.visible" modal header="Confirm Name" :style="{ width: '28rem' }"
        @hide="isLoading = false">
        <form action="" @submit.prevent="saveEditClaim(inputNameDialog.name)">
            <div class="mb-1 text-lg font-medium">Your Name (Agent Name)</div>
            <InputText ref="inputNameDialogInputTextRef" class="" v-model="inputNameDialog.name" type="text" />
        </form>
        <div class="flex gap-3 w-100 justify-content-end mt-6">
            <Button label="Save" class="p-button-raised" @click="saveEditClaim(inputNameDialog.name)"
                :disabled="inputNameDialog.name.length <= 0" />
        </div>
    </Dialog>
</template>



<script setup>
import { convertPrimeVueCalendar } from '../../utils/convertPrimeVueCalendar';
import { ref, onMounted, defineModel, defineProps, defineEmits, nextTick } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import axios from "axios";

const inputNameDialog = ref({ visible: false, name: "" });

const show = ref(false);
const props = defineProps(['claimData'])
const statusOptions = ['New', 'Testing', 'Claiming', 'Received', 'Pending Pickup', 'Settled', 'Closed'];
const emit = defineEmits(['saved'])

const inputNameDialogInputTextRef = ref(null);

// Create copy of claimData
const initialData = ref(JSON.parse(JSON.stringify(props.claimData)));

const showDialog = () => {
    show.value = true;
    initialData.value = JSON.parse(JSON.stringify(props.claimData));
    initialData.value.itemName = initialData.value.ST_ITEM_CODE + ' - ' + initialData.value.item_description;
    isLoading.value = false;
    inputNameDialog.value = { visible: false, name: "" };
}

const editClaimRules = {
    quantity_in_shop: { requiredIf: requiredIf(() => initialData.value.claim_type == 'Customer'), numeric: true, maxValue: maxValue(initialData.value.quantity) },
    contact_details_1: { requiredIf: requiredIf(() => initialData.value.claim_type == 'Customer') },
    agent_name: { required },
}

const v$dialogAdd = useVuelidate(editClaimRules, initialData);

const isLoading = ref(false);
const saveEditClaim = async (name) => {
    isLoading.value = true;

    if (name == undefined || name == null) {
        inputNameDialog.value.visible = true;

        await nextTick(() => {
            inputNameDialogInputTextRef.value.$el.focus();
        });
        return;
    }
    inputNameDialog.value.visible = false;

    const data = {
        id: initialData.value.id,
        date: convertPrimeVueCalendar(initialData.value.date),
        status: initialData.value.status,
        agent_name: initialData.value.agent_name,
        customer_name: initialData.value.customer_name,
        contact_details_1: initialData.value.contact_details_1,
        contact_details_2: initialData.value.contact_details_2,
        is_date: convertPrimeVueCalendar(initialData.value.is_date),
        is_no: initialData.value.is_no,
        rc_date: convertPrimeVueCalendar(initialData.value.rc_date),
        rc_no: initialData.value.rc_no,
        quantity: initialData.value.quantity,
        quantity_in_shop: initialData.value.quantity_in_shop,
        problem: initialData.value.problem,
        remark: initialData.value.remark,
        edited_by: name
    };

    await axios({
        method: "PUT",
        url: "claims",
        data: data,
    }).then(
        (result) => {
            isLoading.value = false;
            show.value = false;
            emit('saved');
        },
        (error) => {
            isLoading.value = false;
            initialData.value.errorMessage = error.response.data.message;
        }
    );
}

</script>


<style scoped></style>